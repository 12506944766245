const grading_tbl = [
    { text: "COURSE", value: "title", width: "80%"},
    { text: "ACTION", value: "action", align: 'center', sortable: false}
]

const grading_assessment_tbl = [
    { text: "TITLE", value: "title", width: "60%"}, 
    { text: "FOR CHECKING", value: "checking", width: "20%", align: "center"},
    { text: "DUE DATE", value: "due_date", width: "20%", align: "center"}
]

const grading_assessment_student_tbl = [
    { text: "STUDENT", value: "student", width: "30%"},
    { text: "GRADING STATUS", value: "checked", align: 'center'},
    { text: "DATE ACCOMPLISHED", value: "date", align: 'center'},
    { text: "TIME ACCOMPLISHED", value: "time", align: 'center'},
    { text: "SCORE", value: "score", align: 'center'},
    { text: "ACTION", value: "action", align: 'center', sortable: false }
]

const grading_survey_tbl = [
    { text: "USER", value: "user"},
    { text: "STATUS", value: "status", align: 'center' },
    { text: "NO. OF ATTEMPTS", value: "attempts", align: 'center'},
    { text: "DATE ACCOMPLISHED", value: "date_submitted", align: 'center' },
    { text: "ACTION", value: "action", align: 'center', sortable: false },
]

const grading_evaluation_tbl = [
    { text: "TITLE", value: "title"},
    { text: "NO. OF SUBMITTERS", value: "no_of_submitters", align: "center"}
]

export { grading_tbl, grading_assessment_tbl, grading_assessment_student_tbl, grading_survey_tbl, grading_evaluation_tbl }